import React, { createContext, useContext, useState } from 'react';

const TranslationContext = createContext();

const translations = {
  en: {
    navbar: {
      title: 'Jean-Joseph',
      menu: 'Menu',
      home: 'Home',
      about: 'About',
      contact: 'Contact',
      themeSwitch: 'Light/Dark',
    },
    landing: {
      title: 'Hi there 👋',
      description: "My passion on IT motivates me to take on new challenges, and help clients achieve their digital aspirations. If you are on the lookout for an experimented and reliable freelance Full-Stack developer to spearhead your upcoming projects, I will be glad to help you !",
      contactBtn: 'Contact',
      typed1: 'I am Jean-Joseph',
      typed2: 'A Front-End',
      typed3: 'Back-End',
      typed4: 'Full-Stack developer',
    },
    about: {
      title: 'To sum up :',
      description1: '- Languages: HTML, CSS, JavaScript, TypeScript, Python, php',
      description2: '- Libraries & Frameworks: jQuery, React, Django, Node.js frameworks (NextJs, Express.js), Material UI, and Bootstrap',
      description3: '- Version Control: Git & GitHub',
      description4: '- Cloud Technologies: Cloud Native practices, DevOps, CI/CD',
      description5: '- Containerization: Docker, Kubernetes, OpenShift',
      description6: '- Database Management: SQL, NoSQL',
      description7: '- Cybersecurity: Application Security best practices',
      description8: '- Microservices and Serverless Computing',
      description9: '- Good SEO practices',
      description10: '- CMS : WordPress',
      description11: 'My goal is to amplify your vision, and turn your concepts into a digital masterpiece.',
    },
    projects: {
      title: 'Accomplishments',
    },

    skills: {
      title: "I mainly use",
    },
   
    contacts: {
        name: "Name/ Company Name",
        placeholder: 'Ashley Giraud/ Company XYZ',
        PhoneNumber: 'Phone Number',
        Subject: 'Subject',
        message: 'Type your message',
        sendBtn: 'Send',
      },
    },

  fr: {
    navbar: {
      title: 'Jean-Joseph',
      menu: 'Menu',
      home: 'Accueil',
      about: 'À propos',
      contact: 'Contact',
      themeSwitch: 'Clair/Sombre',
    },
    landing: {
      title: 'Bonjour 👋',
      description: "Ma passion dans le domaine de l'IT me motive à relever de nouveaux défis, et à aider mes clients à atteindre leurs aspirations numériques. Si vous êtes à la recherche d'un développeur Full-Stack freelance expérimenté, et fiable pour mener vos projets à venir, je serai ravi de vous aider !",
      contactBtn: 'Contact',
      typed1: "Je m'appelle Jean-Joseph",
      typed2: 'Je suis un Développeur Front-End',
      typed3: 'Back-End',
      typed4: 'Full-Stack',
    },
    about: {
      title: 'En résumé :',
      description1: '- Langages : HTML, CSS, JavaScript, TypeScript, Python, php',
      description2: "- Bibliothèques et Frameworks : jQuery, React, Django, frameworks Node.js (NextJs, Express.js), Material UI, et Bootstrap",
      description3: '- Contrôle de version : Git & GitHub',
      description4: '- Technologies Cloud : Pratiques natives du cloud, DevOps, CI/CD',
      description5: '- Conteneurisation : Docker, Kubernetes, OpenShift',
      description6: '- Gestion de base de données : SQL, NoSQL',
      description7: '- Cybersécurité : Bonnes pratiques de sécurité des applications',
      description8: '- Microservices, et informatique sans serveur',
      description9: '- Bonnes pratiques SEO',
      description10: '- CMS : WordPress',
      description11: "Mon objectif est d'amplifier votre vision, et de transformer vos concepts en une œuvre numérique.",
    },
    projects: {
      title: 'Réalisations',
    },

    skills: {
      title: "J'utilse principalement",
    },
   
    contacts: {
        name: "Nom/ Raison Social",
        placeholder: 'Ashley Giraud/ Entreprise XYZ',
        PhoneNumber: 'Numéro de téléphone',
        Subject: 'Sujet',
        message: 'Écrivez votre message',
        sendBtn: 'Envoyer',
      },
    },  
};

const TranslationProvider = ({ children }) => {
  const [language, setLanguage] = useState('en');

  const translate = (key, section) => {
    return translations[language] && translations[language][section] && translations[language][section][key]
      ? translations[language][section][key]
      : key;
  };

  const changeLanguage = (newLanguage) => {
    setLanguage(newLanguage);
  };

  return (
    <TranslationContext.Provider value={{ translate, changeLanguage, currentLanguage: language }}>
      {children}
    </TranslationContext.Provider>
  );
};

const useTranslation = () => {
  return useContext(TranslationContext);
};

export { TranslationProvider, useTranslation };

