/* eslint-disable */
import { contactsImage } from './images'

export const theLightTheme = {
    type: 'light',
    primary: '#2485bd',
    quaternary: '#e4e5f2',
    quaternaryLight: '#F7F9F9',
    secondary: '#ebf1f2',
    tertiary: '#2c3b4a',
    fifth: '#ffffff',
    sixth: '#0f1419',
    buttonColor: '#0F1419',
    contactsimg: contactsImage
}

export const theDarkTheme = {
    type: 'dark',
    primary: '#2485bd',
    secondary: '#0a4557',
    quaternary: '#0b2633',
    quaternaryLight: '#084457',
    tertiary: '#EFF3F4',
    fifth: '#ffffff',
    sixth: '#0f1419',
    buttonColor: '#8B98A5',
    contactsimg: contactsImage
}
