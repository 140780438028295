
import emergency from '../assets/png/emergency.png';
import medical from '../assets/png/medical.png';
import JobSeeker from '../assets/png/job-seeker.png';

export const projectsData = [
    {
        id: 1,
        projectName:'Domus Medica Calenzana',
        tags: ['HTML', 'CSS', 'JavaScript', 'Bootstrap', 'jQuery'],
        code: 'https://github.com/Jean-Joooo/Medical-Center-Website',
        demo: 'https://domus-medica-calenzana.com/',
        image: emergency,
    },
    {
        id: 2,
        projectName: 'Balagne Medical Service',
        tags: ['React', 'CSS', 'Bootstrap', 'JavaScript', 'Packages'],
        demo: 'https://www.balagnemedical.com/',
        image: medical,
    },
    {
        id: 3,
        projectName:'Optimal Job',
        tags: ['WordPress', 'HTML', 'CSS', 'PHP', 'JavaScript'],
        demo: 'https://www.optimaljob.fr/',
        image: JobSeeker,
    },
];
