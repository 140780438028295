export const skillsData = [
    'HTML',
    'CSS',
    'JavaScript',
    'TypeScript',
    'Python',
    'php',
    'Git',
    'GitHub',
    'Bootstrap',
    'MaterialUI',
    'Node JS',
    'Vite JS',
    'jQuery',
    'React',
    'Next JS',
    'Django',
    'Docker',
    'SQLite',
    'MySQL',
    'PostgreSQL',
    'WordPress',
    'Firebase',
    'GCP'
]

