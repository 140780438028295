import emailjs from '@emailjs/browser';
import { makeStyles } from '@mui/styles';
import React, { useContext, useRef, useState } from 'react';
import isEmail from 'validator/lib/isEmail';
import { ThemeContext } from '../../contexts/theme-context';
import ContactUI from '../core-ui/contact/contact-ui';


const Contacts = () => {
  const [open, setOpen] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [success, setSuccess] = useState(false);
  const [errMsg, setErrMsg] = useState('');
  const [showSuccessMessage, setShowSuccessMessage] = useState(true);
  const form = useRef();
  const { theme } = useContext(ThemeContext);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }


    setOpen(false);
  };

  const useStyles = makeStyles((t) => ({
    input: {
      border: `3px solid ${theme.buttonColor}`,
      backgroundColor: `${theme.secondary}`,
      color: `${theme.tertiary}`,
      fontFamily: 'var(--quaternaryFont)',
      fontWeight: 500,
      fontSize: '1rem',
      transition: 'border 0.2s ease-in-out',
      '&:focus': {
        border: `5px solid ${theme.primary}`,
      },
    },
    message: {
      border: `3px solid ${theme.buttonColor}`,
      backgroundColor: `${theme.secondary}`,
      color: `${theme.tertiary}`,
      fontFamily: 'var(--quaternaryFont)',
      fontWeight: 500,
      transition: 'border 0.2s ease-in-out',
      '&:focus': {
        border: `4px solid ${theme.primary}`,
      },
    },
    label: {
      backgroundColor: `${theme.secondary}`,
      color: `${theme.tertiary}`,
      fontFamily: 'var(--quaternaryFont)',
      fontWeight: 500,
      fontSize: '1.2rem',
      padding: '0 5px',
      transform: 'translate(25px,50%)',
      display: 'inline-flex',
    },
    socialIcon: {
      width: '45px',
      height: '45px',
      borderRadius: '50%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: 'black',
      boxShadow: '0px 0px 12px 2px rgb(13, 104, 195)',
      fontSize: '21px',
      color: theme.fifth,
      transition: '250ms ease-in-out',
      '&:hover': {
        transform: 'scale(1.2)',
        color: theme.fifth,
        backgroundColor: theme.sixth,
      },
    },
    detailsIcon: {
      backgroundColor: theme.buttonColor,
      color: theme.secondary,
      borderRadius: '50%',
      width: '45px',
      height: '45px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontSize: '23px',
      boxShadow: '2px 2px 2px 1px rgba(1, 9, 20, .4)',
      transition: '250ms ease-in-out',
      flexShrink: 0,
      '&:hover': {
        transform: 'scale(1.2)',
        color: theme.secondary,
        backgroundColor: theme.tertiary,
      },
    },
    submitBtn: {
      backgroundColor: theme.primary,
      color: theme.secondary,
      textShadow: '0 1px 1px #4f555c, 0 0 0.1em #818994, 0 0 0.1em #0a0a0a',
      transition: '250ms ease-in-out',
      '&:hover': {
        transform: 'scale(1.1)',
        color: theme.primary,
        backgroundColor: theme.fifth,
      },
    },
  }));

  const classes = useStyles();


  const handleContactForm = (e) => {
    e.preventDefault();

    if (name && email && message) {
      if (isEmail(email)) {
        emailjs.sendForm(
          'service_isabm6g',
          'template_h4a0vef',
          form.current,
          'kgPLgBHDJc1CCBC3m')
          .then((result) => {
            console.log('success');
            setSuccess(true);
            setErrMsg('');
            setName('');
            setEmail('');
            setMessage('');
            setShowSuccessMessage(true);
            setOpen(false);
            setTimeout(() => {
            setShowSuccessMessage(true);
            }, 3000);
          }, (error) => {
            console.log(error.text);
          });
      } else {
        setErrMsg('Invalid email');
        setOpen(true);
      }
    } else {
      setErrMsg('Enter all the fields');
      setOpen(true);
    }
  };

  return (
    <ContactUI
      open={open}
      success={success}
      errMsg={errMsg}
      handleClose={handleClose}
      classes={classes}
      handleContactForm={handleContactForm}
      name={name}
      setName={setName}
      form={form}
      email={email}
      setEmail={setEmail}
      message={message}
      setMessage={setMessage}
      showSuccessMessage={showSuccessMessage}
    />
  );
};

export default Contacts;
