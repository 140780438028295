import React from 'react'
import { Helmet } from 'react-helmet'
import Contact from '../../components/container/contact'
import Landing from '../../components/container/landing'
import Navbar from '../../components/container/navbar'
import Projects from '../../components/container/projects'
import About from '../../components/core-ui/about/about'
import Skills from '../../components/core-ui/skills/skills'
import { headerData } from '../../data/headerData'
import { TranslationProvider } from '../../TranslationContext'
import LanguageSwitcher from '../../LanguageSwitcher'

function HomePage() {
    return (
        <div>
            <TranslationProvider>
            <Helmet>
            <title>{headerData.name}Jean-Joseph - Portfolio</title>
            </Helmet>
            <LanguageSwitcher />
            <Navbar />
            <Landing />
            <About />
            <Skills />
            <Projects />
            <Contact />
            </TranslationProvider>
        </div>
    )
}

export default HomePage
