import { Container } from '@mui/material';
import React, { useContext } from 'react';
import codings from '../../../assets/lottie/coding.json';
import { ThemeContext } from '../../../contexts/theme-context';
import AnimationLottie from '../../helper/animation-lottie';
import './about.css';
import { useTranslation } from '../../../TranslationContext';

function About() {
    const { translate } = useTranslation();

    const { theme } = useContext(ThemeContext);
    return (
        <div style={{ backgroundColor: theme.secondary }}>
            <Container className="about" id="about">
                <div className="about-body">
                    <div className="about-description">
                    <h2 style={{ color: theme.primary }}>{translate('title', 'about')}</h2>
                        <p style={{ color: theme.tertiary }}>
                        {translate('description1', 'about')}<br />
                            {translate('description2', 'about')}<br />
                            {translate('description3', 'about')}<br />
                            {translate('description4', 'about')}<br />
                            {translate('description5', 'about')}<br />
                            {translate('description6', 'about')}<br />
                            {translate('description7', 'about')}<br />
                            {translate('description8', 'about')}<br />
                            {translate('description9', 'about')}<br />
                            {translate('description10', 'about')}<br />
                            {translate('description11', 'about')}<br />
                        </p>
                    </div>
                    <div className="about-animation">
                        <AnimationLottie animationPath={codings} />
                    </div>
                </div>
            </Container>
        </div>
    )
}

export default About;
